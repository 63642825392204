<template>
  <h1 class="_heading-1 mb-2" v-t="'login_page.title'" />
  <h2 class="_heading-3 mb-5" v-t="'login_page.sub_title'" />
  <form @submit.prevent="handleSubmit(!v$.$invalid)">
    <p v-t="error" v-if="error" class="mb-3 _error"></p>
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && submitted}">
      <span class="p-label" v-t="'email'"></span>
      <InputText type="email" v-model="v$.email.$model" />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.password.$invalid && submitted}">
      <span class="p-label" v-t="'password'"></span>
      <Password :feedback="false" v-model="v$.password.$model" toggleMask />
    </label>

    <router-link :to="{ name: 'ForgotPassword' }" v-t="'login_page.forgot_password'" class="_link _link-secondary" />

    <Button :disabled="submiting" type="submit" class="_full-width mb-4 p-button-icon-right mt-5">
      <span v-t="'login_page.sign_in'" />
      <Icon name="log_in" className="p-field-icon" />
    </Button>
    <Button class="p-button-outlined p-button-secondary _full-width">
      <span v-t="'login_page.contact_support'" />
    </Button>
  </form>
</template>

<script>
import cookies from 'js-cookie'
import { reactive, ref } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';

import { menu } from '../../router'

import { Icon } from '../../components/ui'

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return await response.json();
}

export default {
  components: {
    InputText,
    Password,
    Button,
    Icon,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const submitted = ref(false);
    const submiting = ref(false);
    const error = ref('')

    const state = reactive({
      email: '',
      password: '',
    });
    const rules = {
      email: { required, email },
      password: { required },
    };

    const v$ = useVuelidate(rules, state)


    const handleSubmit = (isFormValid) => {
      submitted.value = true;
      v$.value.$touch()
      if (!isFormValid) {
        return;
      }
      submiting.value = true;
      postData(`${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOST}/api/auth/login`, state)
      .then((data) => {
        if (data.error) {
          switch (data.error) {
            case 'Unauthorized':
              error.value = 'login_page.unauthorized'
              break;
            default:
              error.value = 'something_went_wrong'
              break;
          }
          return
        }
        cookies.set('token', data.access_token, { expires: data.expires_in * 10000 / 8.64e+7 })
        cookies.set('user', JSON.stringify(data.user), { expires: data.expires_in * 10000 / 8.64e+7 })
        cookies.set('no-refresh', true, { expires: data.expires_in * 10000 / 2 / 8.64e+7 })
        store.commit('account/setAccount', data.user)
        router.push(menu[store.state.account.role][0].to)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() =>  {
        submiting.value = false;
      });
    }

    return {
      v$,
      state,
      error,
      submitted,
      submiting,
      handleSubmit,
    }
  }
}
</script>

